import React from "react";
import "./bien.css";

function Bien (){
  return(
    <div className={"app-bien"}>
      <span>Biens</span>
    </div>
  )
}

export default Bien;
