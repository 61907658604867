import React, {useEffect, useState} from "react";
import "./app.css";
import Sidebar from "./sidebar/sidebar";
import Header from "./header/header";
import Main from "./main/main";
import Footer from "./footer/footer";
import Login from "./login/login";
import userContext from "./user-context";

function App() {

  const [isAuthenticated, setisAuthenticated] = useState(false);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [token, setToken] = useState("");
  const [APIServerAddress, setAPIServerAddress] = useState("");

  useEffect(() => {
    if (process.env.NODE_ENV === "development") {
      setAPIServerAddress(process.env.REACT_APP_API_SERVER_IF_DEV);
    } else if (process.env.NODE_ENV === "production"){
      setAPIServerAddress(process.env.REACT_APP_API_SERVER_IF_PROD);
    }
    console.log("NODE_ENV : " + process.env.NODE_ENV);
    console.log("API server IP : " + APIServerAddress);
  },[APIServerAddress])



  if (!isAuthenticated) {
    return (
      <div className="app">
        <userContext.Provider value={{
          username: username, setUsername: setUsername,
          password: password, setPassword: setPassword,
          isAuthenticated: isAuthenticated, setisAuthenticated: setisAuthenticated,
          token: token, setToken: setToken,
          APIServerAddress: APIServerAddress, setAPIServerAddress: setAPIServerAddress
        }}>
          <Login/>
        </userContext.Provider>
      </div>
    )
  } else {
    return (
      <div className="app">
        <userContext.Provider value={{
          username: username, setUsername: setUsername,
          token: token, setToken: setToken
        }}>
          <Header/>
          <Sidebar/>
          <Main/>
          <Footer/>
        </userContext.Provider>
      </div>
    );
  }
}

export default App;
