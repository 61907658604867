import React from "react";
import "./immeuble.css";

function Immeuble() {
  return (
    <div className={"app-immeuble"}>
      <span>Immeuble</span>
    </div>
  )
}

export default Immeuble;
