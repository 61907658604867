import React from "react";
import "./footer.css";

function Footer() {
  return(
    <div className="app-footer">
      &copy;2020 - Move On Up srl
    </div>
  )
}

export default Footer;
