import React from "react";
import "./main.css";

import Immeuble from "./../immeuble/immeuble";
import Bien from "../bien/bien";
import Dashboard from "../dashboard/dashboard";


import {Route, Routes} from "react-router-dom";
import NotFound from "../notFound/notFound";

function Main(){


  return (
    <div className="app-main">
      <Routes>
        <Route path="/" element={<Dashboard />} />
        <Route path="/immeuble" element={<Immeuble />} />
        <Route path="/bien" element={<Bien />} />
        <Route path="/*" element={<NotFound />} />
      </Routes>
    </div>
  )
}

export default Main;
