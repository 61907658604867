import React, {useContext} from "react";
import "./dashboard.css";
import context from "../user-context";

function Dashboard() {
  const { value, value2 } = useContext(context);
  return(
    <div className={"app-dashboard"}>
      <span>Dashboard</span>
      <p> Hello {value}</p>
      <p> This is your password {value2}</p>
    </div>
  )
}

export default Dashboard;
