import React from "react";
import "./header.css";

function Header() {

  return (
    <header className="app-header">
      Gestion Privative
    </header>
  )
}

export default Header;
