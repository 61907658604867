import React, {useContext} from "react";
import "./login.css";
import axios from "axios";
import context from "../user-context";
import {useNavigate} from "react-router-dom";

function Login() {



  const navigate = useNavigate();

  const {
    username, setUsername,
    password, setPassword,
    setisAuthenticated,
    setToken,
    APIServerAddress
  } = useContext(context);

  const handleSubmit = (evt) => {
    evt.preventDefault();
    getToken()
  }

  function getToken() {
    console.log("u: " + username + " - p: " + password);
    console.log("https://" + APIServerAddress + "/v1/login");
    axios.post("https://" + APIServerAddress + "/v1/login", {
      username: username,
      password: password
    })
      .then(function (response) {

        // handle success
        console.log(response.data.token);
        setisAuthenticated("true");
        setToken(response.data.token);
        navigate("/");
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      })
      .then(function () {
        // always executed
      });
  }

  return(
    <div className={"app-login"}>
      <form onSubmit={handleSubmit} className={"app-login-form"}>
        <label>
          username:
          <input
            type="text"
            value={username || ""}
            onChange={e => setUsername(e.target.value)}
          />
        </label>
        <label>
          password:
          <input
            type="text"
            value={password || ""}
            onChange={e => setPassword(e.target.value)}
          />
        </label>
        <input type="submit" value="Submit" />
      </form>
    </div>
    )
}

export default Login;
