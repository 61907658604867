import React, { useContext } from "react";
import "./sidebar.css";
import { NavLink } from "react-router-dom";
import context from "../user-context";

function Sidebar() {

  const { setUsername, setPassword, setisAuthenticated } = useContext(context);

    function handleClick() {
      setisAuthenticated(false);
      setUsername(null);
      setPassword(null);
    }

  return (
    <div className="app-sidebar">
      <nav>
        <NavLink exact={"true"} to={"/"} activeClassName="navLinkSelected" end>Dashboard</NavLink>
        <NavLink to="/immeuble" activeClassName="navLinkSelected">Immeuble</NavLink>
        <NavLink to="/bien" activeClassName="navLinkSelected">Bien</NavLink>
        <NavLink exact={"true"} to={"/login"} onClick={handleClick} activeClassName="navLinkSelected" end>Logout</NavLink>
      </nav>
    </div>
  )
}

export default Sidebar;


