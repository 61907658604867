import React from "react";
import "./notFound.css";

function NotFound() {
  return (
    <h1>404 - page not found</h1>
  )
}

export default NotFound;
